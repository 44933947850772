
import { Component } from "vue-property-decorator";
import HospitalHeader from "@/components/HospitalHeader.vue";
import Table from "@/components/Table.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import FacilityModule from "@/store/facility";
import AuthModule from "@/store/auth";
import CheckCommonLogin from "@/utils/CheckCommonLogin";
import Button from "@/components/Button.vue";
import FacilityService from "@/services/facilities";
import { deleteNullProp } from "@/utils/Util";
import { HospitalData } from "@/@types/management";

@Component({
  name: "MailSetting",
  components: {
    HospitalHeader,
    Table,
    ManagementFooter,
    Button,
  },
})
export default class MailSetting extends CheckCommonLogin {
  private get hospitalId() {
    return FacilityModule.hospitalId;
  }
  private get me() {
    return AuthModule.me;
  }

  private hospitalData: HospitalData | null = null;

  private async cancel() {
    this.loading = true;
    const res = await FacilityService.get(this.hospitalId);
    this.hospitalData = res.data.data[0];
    this.loading = false;
    this.isEditMode = false;
  }

  private async mounted(): Promise<void> {
    const res = await FacilityService.get(this.hospitalId);
    this.hospitalData = res.data.data[0];
    // 既存データの書き換え
    if (
      this.hospitalData !== null &&
      this.hospitalData.has_notification_new === null &&
      this.hospitalData.has_notification_cancel === null &&
      this.hospitalData.has_notification_remind === null &&
      this.hospitalData.has_notification_update === null
    ) {
      this.hospitalData.has_notification_new = true;
      this.hospitalData.has_notification_cancel = true;
      this.hospitalData.has_notification_remind = true;
      this.hospitalData.has_notification_update = true;
      const payload = deleteNullProp(this.hospitalData);
      const res = await FacilityService.update(this.hospitalId, payload);
      this.hospitalData = res.data.data[0];
    }
  }

  private isEditMode = false;

  private sendMail = false;

  private loading = false;

  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }

  private async update() {
    try {
      this.loading = true;
      const payload = deleteNullProp(this.hospitalData);
      const res = await FacilityService.update(this.hospitalId, payload);
      this.hospitalData = res.data.data[0];
      this.loading = false;
      this.isEditMode = false;
    } catch (error) {
      this.showModal("error");
      this.loading = false;
    }
  }
}
